import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimates = () => {
  const swr = useSWR(`/estimate/records`, async () => {
    try {
      const url = `${getApiUrl()}/estimate/records`;
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePostEstimate = () => {
  const toast = useToast();
  const postEstimate = async (recordUid) => {
    try {
      const url = `${getApiUrl()}/estimate/${recordUid}/config`;
      const result = await axios.post(url);
      return result.data;
    } catch (error) {
      toast('An error occurred creating estimate');
      throw Error(error);
    }
  };

  return postEstimate;
};

const usePostEstimateV2 = () => {
  const toast = useToast();
  const postEstimate = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred creating estimate');
      throw Error(error);
    }
  };

  return postEstimate;
};

const usePutEstimate = () => {
  const toast = useToast();
  const postEstimate = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${estimateUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred updating estimate');
      throw Error(error);
    }
  };

  return postEstimate;
};

const useDuplicateEstimate = () => {
  const toast = useToast();
  const duplicateEstimate = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${estimateUid}/duplicate`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred creating estimate');
      throw Error(error);
    }
  };

  return duplicateEstimate;
};

const usePostEstimateFromRecord = () => {
  const toast = useToast();
  const postEstimateFromRecord = async (projectUid, recordUid) => {
    try {
      const url = `${getApiUrl()}/estimate/new/from_record`;
      const result = await axios.post(url, {
        project_uid: projectUid,
        record_uid: recordUid
      });
      return result.data;
    } catch (error) {
      toast('An error occurred creating estimate');
      throw Error(error);
    }
  };

  return postEstimateFromRecord;
};

const useCopyEstimateConfig = () => {
  const toast = useToast();
  const copyEstimateConfig = async (sourceRecordUid, destinationRecordUid) => {
    try {
      const url = `${getApiUrl()}/estimate/copy/config/src/${sourceRecordUid}/to/${destinationRecordUid}`;
      const result = await axios.post(url);
      return result.data;
    } catch (error) {
      toast('An error occurred copying estimate');
      throw Error(error);
    }
  };

  return copyEstimateConfig;
};

const useEstimate = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate/${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePutEstimateRecordTemplate = () => {
  const toast = useToast();
  const putEstimateRecordTemplate = async (estimateUid, recordTemplateUid) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${estimateUid}/record-template/${recordTemplateUid}`;
      const result = await axios.put(url);
      return result.data;
    } catch (error) {
      toast('An error occurred updating template');
      throw Error(error);
    }
  };

  return putEstimateRecordTemplate;
};

const usePostEstimateComps = () => {
  const toast = useToast();
  const postEstimateComps = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${estimateUid}/comps`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred adding comps');
      throw Error(error);
    }
  };

  return postEstimateComps;
};

const useToggleLock = () => {
  const toast = useToast();
  const toggleLock = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${estimateUid}/toggle-lock`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred updating estimate');
      throw Error(error);
    }
  };

  return toggleLock;
};

const useDeleteEstimate = () => {
  const toast = useToast();
  const deleteEstimate = async (estimateUid) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${estimateUid}`;
      const result = await axios.delete(url);
      return result.data;
    } catch (error) {
      toast('An error occurred deleting estimate');
      throw Error(error);
    }
  };

  return deleteEstimate;
};

const useShareEstimate = () => {
  const toast = useToast();
  const shareEstimate = async (uid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate/${uid}/share`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred sharing estimate');
      throw Error(error);
    }
  };

  return shareEstimate;
};

const useEstimateDetailedBudgetStructure = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate/${estimateUid}/detailed-budget-structure`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

export {
  useEstimates,
  usePostEstimate,
  usePostEstimateV2,
  usePutEstimate,
  useDuplicateEstimate,
  usePostEstimateFromRecord,
  useCopyEstimateConfig,
  useEstimate,
  usePutEstimateRecordTemplate,
  usePostEstimateComps,
  useToggleLock,
  useDeleteEstimate,
  useEstimateDetailedBudgetStructure,
  useShareEstimate
};
