import { createContext, useContext } from 'react';

const ToastContext = createContext({ toasts: [] });
ToastContext.displayName = 'ToastContext';

const useToast = () => {
  const toast = useContext(ToastContext);
  return toast;
};

export { ToastContext, useToast };
