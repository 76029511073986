import { useState } from 'react';
import { CircleUserRound, LogOut } from 'lucide-react';

import AccountDialog from './AccountDialog';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';

import { useLogoutUser } from '@/helpers/auth0';
import { useSelf } from '@/data/user';

const UserAvatar = () => {
  const self = useSelf();
  const logoutUser = useLogoutUser();
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);

  const handleOpenAccount = () => {
    setIsAccountDialogOpen(true);
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
    } catch (err) {
      //   setIsLoggingOut(false);
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Avatar>
            <AvatarImage src="" alt={`${self.data.firstname} ${self.data.lastname}`} />
            <AvatarFallback>{`${self.data.firstname.charAt(0).toUpperCase()}${self.data.lastname.charAt(0).toUpperCase()}`}</AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>
            <p>
              {self.data.firstname} {self.data.lastname}
            </p>
            <span className="uppercase text-xs text-muted-foreground">
              {self.data.roles?.[0] || ''}
              {self.data.roles?.[1] && `, ${self.data.roles[1]}`}
            </span>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleOpenAccount}>
            <CircleUserRound />
            My Account
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleLogout}>
            <LogOut />
            Sign Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {isAccountDialogOpen && <AccountDialog setOpen={setIsAccountDialogOpen} />}
    </>
  );
};

export default UserAvatar;
