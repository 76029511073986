import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimateCostSubFields = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate-cost-sub-field?estimateUid=${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutEstimateCostSubField = () => {
  const toast = useToast();
  const putEstimateSubCostField = async (estimateSubCostFieldUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-cost-sub-field/${estimateSubCostFieldUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred updating sub cost field');
      throw Error(error);
    }
  };

  return putEstimateSubCostField;
};

const usePostEstimateCostSubField = () => {
  const toast = useToast();
  const postEstimateSubCostField = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-cost-sub-field`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred adding sub cost field');
      throw Error(error);
    }
  };

  return postEstimateSubCostField;
};

const useDeleteEstimateCostSubField = () => {
  const toast = useToast();
  const putEstimateSubCostField = async (estimateSubCostFieldUid) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-cost-sub-field/${estimateSubCostFieldUid}`;
      const result = await axios.delete(url);
      return result.data;
    } catch (error) {
      toast('An error occurred deleting sub cost field');
      throw Error(error);
    }
  };

  return putEstimateSubCostField;
};

export { useEstimateCostSubFields, usePutEstimateCostSubField, usePostEstimateCostSubField, useDeleteEstimateCostSubField };
