import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';

const useCostSubFields = (recordUid) => {
  const url = `${getApiUrl('v2')}/cost-sub-field?recordUid=${recordUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePostEstimateBulkSubFieldUpdates = () => {
  const postEstimateBulkSubFieldUpdates = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/cost-sub-field/bulk-update/estimate/${estimateUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      window.onerror(error);
      throw Error(error);
    }
  };

  return postEstimateBulkSubFieldUpdates;
};

export { useCostSubFields, usePostEstimateBulkSubFieldUpdates };
