import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';

const useFields = (projectRecordUid, fieldCategory) => {
  let fieldCategoryQueryStr = '';
  if (fieldCategory) {
    fieldCategoryQueryStr = `&fieldCategory=${fieldCategory}`;
  }
  const url = `${getApiUrl('v2')}/field?projectRecordUid=${projectRecordUid}${fieldCategoryQueryStr}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePostRecordBulkFieldUpdates = () => {
  const postRecordBulkFieldUpdates = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/field/bulk-update/estimate/${estimateUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      window.onerror(error);
      throw Error(error);
    }
  };

  return postRecordBulkFieldUpdates;
};

export { useFields, usePostRecordBulkFieldUpdates };
