import { useParams } from 'react-router-dom';
import { useState, useCallback } from 'react';
import { useAsyncEffect, useWillUnmount, useDebounce } from 'rooks';
import { useDispatch, useSelector } from 'react-redux';
import { useRollbar } from '@rollbar/react';
import { createSlice } from '@reduxjs/toolkit';
import { calculationWorker } from './_worker/worker';
import { useEstimateCostFields } from '@/data/estimateCostField';
import { useEstimateDesignFields, usePostEstimateBulkDesignFieldUpdates } from '@/data/estimateDesignField';
import { useEstimateCostBucketGroups } from '@/data/estimateCostBucketGroup';
import { useEstimateCostBuckets } from '@/data/estimateBucket';
import { useEstimateCostFieldGroups } from '@/data/estimateCostFieldGroup';
import { useEstimateComps } from '@/data/estimateComp';
import { useEstimateCompCostBuckets } from '@/data/estimateCompCostBucket';
import { useEstimateCompCostFields } from '@/data/estimateCompCostField';
import { useEstimateDetailedBudgetStructure, useEstimate } from '@/data/estimate';
import { useEstimateEsclationRates } from '@/data/estimateEscalationRate';
import { useEstimateIndirectCosts } from '@/data/estimateIndirectCost';
import { useEstimateCostSubFields } from '@/data/estimateCostSubField';
import { useEstimateInputProgrammingConfigGroups } from '@/data/estimateInputProgrammingConfigGroup';
import { usePostRecordBulkFieldUpdates } from '@/data/field';
import { usePostEstimateBulkSubFieldUpdates } from '@/data/costSubField';
import { useFormulas } from '@/data/formula';

const useCalculations = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const rollbar = useRollbar();
  const params = useParams();
  const estimate = useEstimate(params.estimateUid);
  const estimateComps = useEstimateComps(params.estimateUid);
  const estimateDesignFields = useEstimateDesignFields(params.estimateUid);
  const estimateCostFields = useEstimateCostFields(params.estimateUid);
  const estimateCostBucketGroups = useEstimateCostBucketGroups(params.estimateUid);
  const estimateCostFieldGroups = useEstimateCostFieldGroups(params.estimateUid);
  const estimateCostBuckets = useEstimateCostBuckets(params.estimateUid);
  const estimateCompCostBuckets = useEstimateCompCostBuckets(params.estimateUid);
  const estimateCompCostFields = useEstimateCompCostFields(params.estimateUid);
  const estimateDetailedBudgetStructure = useEstimateDetailedBudgetStructure(params.estimateUid);
  const estimateEscalationRates = useEstimateEsclationRates(params.estimateUid);
  const estimateIndirectCosts = useEstimateIndirectCosts(params.estimateUid);
  const estimateCostSubFields = useEstimateCostSubFields(params.estimateUid);
  const formulas = useFormulas(params.estimateUid);
  const estimateProgrammingConfigGroups = useEstimateInputProgrammingConfigGroups(params.estimateUid);
  const estimateCostFieldCalculations = useSelector((state) => state.estimateCalculations.field);
  const estimateSubFieldCalculations = useSelector((state) => state.estimateCalculations['sub-field']);
  const estimateDesignFieldCalculations = useSelector((state) => state.estimateCalculations.formula['design-field']);
  const designFieldUpdateCount = useSelector((state) => state.estimateCalculations.designFieldUpdateCount);
  const postRecordBulkFieldUpdates = usePostRecordBulkFieldUpdates();
  const postEstimateBulkSubFieldUpdates = usePostEstimateBulkSubFieldUpdates();
  const postEstimateBulkDesignFieldUpdates = usePostEstimateBulkDesignFieldUpdates();

  // When the hook unmounts, we can reset the estimate calculations
  useWillUnmount(() => {
    dispatch(actions.resetEstimate());
  });

  // Define the dependencies here, since they are used twice below
  const dependencies = [
    estimate.data,
    estimateComps.data,
    designFieldUpdateCount,
    estimateCostFields.data,
    estimateCostBucketGroups.data,
    estimateCostFieldGroups.data,
    estimateCostBuckets.data,
    estimateCompCostBuckets.data,
    estimateCompCostFields.data,
    estimateDetailedBudgetStructure.data,
    estimateEscalationRates.data,
    estimateIndirectCosts.data,
    estimateCostSubFields.data,
    estimateProgrammingConfigGroups.data,
    formulas.data
  ];

  const runCalculations = useCallback(async () => {
    setIsError(false);

    try {
      // Create a copy of the old data so we can do a diff after the calculations below
      const prevEstimateCostFieldCalculations = { ...estimateCostFieldCalculations };
      const prevEstimateDesignFieldCalculations = { ...estimateDesignFieldCalculations };
      const prevEstimateCostSubFieldCalculations = { ...estimateSubFieldCalculations };

      // Run the actual calculations inside of the worker
      const calculationResult = await calculationWorker.runCalculations({
        estimate: estimate.data,
        estimateComps: estimateComps.data,
        estimateDesignFields: estimateDesignFields.data,
        estimateCostFields: estimateCostFields.data,
        estimateCostBucketGroups: estimateCostBucketGroups.data,
        estimateCostFieldGroups: estimateCostFieldGroups.data,
        estimateCostBuckets: estimateCostBuckets.data,
        estimateCompCostBuckets: estimateCompCostBuckets.data,
        estimateCompCostFields: estimateCompCostFields.data,
        estimateDetailedBudgetStructure: estimateDetailedBudgetStructure.data,
        estimateEscalationRates: estimateEscalationRates.data,
        estimateIndirectCosts: estimateIndirectCosts.data,
        estimateCostSubFields: estimateCostSubFields.data,
        estimateProgrammingConfigGroups: estimateProgrammingConfigGroups.data,
        formulas: formulas.data
      });

      // Set the redux state for the new calculations
      // This will update the UI right away, before more logic is done below
      dispatch(actions.setCalculations(calculationResult));

      // If the calculations have been initialized, we can update the estimate fields

      // Find all of the changed estimate design fields that are driven by formulas
      const { updatedEstimateDesignFields, updatedRecordDesignFields } = await calculationWorker.getChangedFormulaDrivenEstimateDesignFieldValues({
        estimateDesignFieldCalculations: calculationResult.formulaCalculations['design-field'],
        prevEstimateDesignFieldCalculations,
        estimateDesignFields: estimateDesignFields.data
      });

      //  If any of the estimate design fields that are driven by formulas have changed, update the local swr state first
      if (updatedEstimateDesignFields.length > 0) {
        await estimateDesignFields.mutate((current) => {
          const newContent = { ...current.content };
          for (const designField of updatedEstimateDesignFields) {
            newContent[designField.fieldUid] = {
              ...newContent[designField.fieldUid],
              value: designField.value
            };
          }
          return { ...current, content: newContent };
        }, false);
      }

      // Find the diffs for record cost fields and cost sub fields
      // This is the project record that the estimate is tied to
      const [updatedRecordCostFields, updatedRecordCostSubFields] = await Promise.all([
        calculationWorker.getChangedRecordCostFields({
          costFieldCalculations: calculationResult.fieldCalculations,
          prevEstimateCostFieldCalculations,
          estimateCostFields: estimateCostFields.data
        }),
        calculationWorker.getChangedRecordCostSubFields({
          costSubFieldCalculations: calculationResult.subFieldCalculations,
          prevEstimateCostSubFieldCalculations,
          estimateCostSubFields: estimateCostSubFields.data
        })
      ]);

      const updatedRecordFields = [...updatedRecordCostFields, ...updatedRecordDesignFields];

      // Manually call the api to update record cost, design and sub fields
      const apiUpdates = [
        updatedEstimateDesignFields.length > 0 && postEstimateBulkDesignFieldUpdates(params.estimateUid, updatedEstimateDesignFields),
        updatedRecordFields.length > 0 && postRecordBulkFieldUpdates(params.estimateUid, updatedRecordFields),
        updatedRecordCostSubFields.length > 0 && postEstimateBulkSubFieldUpdates(params.estimateUid, updatedRecordCostSubFields)
      ].filter(Boolean);

      // We can fail these silently with allSettled because they dont impact the estimate
      await Promise.allSettled(apiUpdates);

      setIsInitialized(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      rollbar.error(err);
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ...dependencies]);

  const debouncedRunCalculations = useDebounce(runCalculations, 50);

  useAsyncEffect(debouncedRunCalculations, dependencies);

  return { isInitialized, isError };
};

const calculationsInitialState = {
  designFieldUpdateCount: 0,
  'sub-field': {},
  field: {},
  formula: {
    'design-field': {}
  },
  'field-group': {},
  bucket: {},
  'bucket-group': {},
  total: {},
  'comp-field': {},
  'comp-bucket': {},
  'comp-escalation': {},
  'comp-msa-premium': {},
  'indirect-cost': {},
  'field-overrides-escalation-rate': 0,
  'total-cost': {}
};

const { actions, reducer } = createSlice({
  name: 'estimateCalculations',
  initialState: calculationsInitialState,
  reducers: {
    setCalculations: (state, action) => {
      state['sub-field'] = action.payload.subFieldCalculations;
      state['formula'] = action.payload.formulaCalculations;
      state.field = action.payload.fieldCalculations;
      state['field-group'] = action.payload.fieldGroupCalculations;
      state['bucket-group'] = action.payload.bucketGroupCalculations;
      state['bucket'] = action.payload.bucketCalculations;
      state['comp-field'] = action.payload.compFieldCalculations;
      state['comp-bucket'] = action.payload.compBucketCalculations;
      state['comp-escalation'] = action.payload.compEscalationCalculations;
      state['comp-msa-premium'] = action.payload.compMsaPremiumCalculations;
      state['indirect-cost'] = action.payload.indirectCostCalculations;
      state['field-overrides-escalation-rate'] = action.payload.fieldsOverridesEscalationRate;
      state['total-cost'] = action.payload.totalCalculations;
    },
    incrementDesignFieldUpdateCount: (state) => {
      state.designFieldUpdateCount += 1;
    },
    resetEstimate: () => {
      return calculationsInitialState;
    }
  }
});

export { useCalculations, reducer, actions };
