import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Error } from '@/components/ui/error';
import { DialogFooter } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useSelf, usePutPassword } from '@/data/user';

const formSchema = z
  .object({
    password: z.string().min(1, { message: 'Required' }),
    newPassword: z.string().min(8),
    confirm: z.string().min(8)
  })
  .refine(({ newPassword, confirm }) => newPassword === confirm, { message: 'Passwords do not match', path: ['confirm'] });

const PasswordForm = ({ setOpen }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const self = useSelf();
  const putPassword = usePutPassword();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      newPassword: '',
      confirm: ''
    }
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    setApiError('');

    try {
      const response = await putPassword(values);
      await self.mutate(response, false);
      setIsSubmitting(false);
      setOpen(false);
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Current Password</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirm"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm New Password</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {apiError && <Error message={apiError} />}
        <DialogFooter>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting && <Loader2 className="animate-spin" />}Change Password
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default PasswordForm;
