import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Provider as ReduxProvider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, createTheme, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import App from './App';
import ErrorDisplay from './components/app/ErrorDisplay';
import ToastProvider from './components/app/ToastProvider';
import { store } from './redux/store';
import './index.css';
import GoogleMapsLoader from './components/googleMaps/GoogleMapsLoader';

import { getAuth0Domain, getAuth0ClientId, getAuth0RedirectUri, getAuth0Audience } from '@/helpers/auth0';
import { getRollbarConfig } from '@/helpers/rollbar';
import theme from '@/helpers/theme';

import '@fontsource-variable/inter';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Auth0Provider
    domain={getAuth0Domain()}
    clientId={getAuth0ClientId()}
    authorizationParams={{
      redirect_uri: getAuth0RedirectUri(),
      audience: getAuth0Audience()
    }}
  >
    <CssBaseline injectFirst={false} />
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={createTheme(theme)}>
        <Provider config={getRollbarConfig}>
          <ErrorBoundary fallbackUI={ErrorDisplay}>
            <ReduxProvider store={store}>
              <BrowserRouter getUserConfirmation={() => {}}>
                <GoogleMapsLoader>
                  <ToastProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <App />
                    </LocalizationProvider>
                  </ToastProvider>
                </GoogleMapsLoader>
              </BrowserRouter>
            </ReduxProvider>
          </ErrorBoundary>
        </Provider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </Auth0Provider>
);
