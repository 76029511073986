const formatNumber = (value = '', minDecimalPlaces = 0, maxDecimalPlaces = minDecimalPlaces) => {
  if (minDecimalPlaces > maxDecimalPlaces) {
    throw Error('Min decimal places must be more than max decimal places');
  }

  if (value === null || value === undefined) {
    return '';
  }

  return value.toLocaleString('en-US', { minimumFractionDigits: minDecimalPlaces, maximumFractionDigits: maxDecimalPlaces });
};

export const safeDivide = (a, b) => {
  return b === 0 ? 0 : a / b;
};

export { formatNumber };
