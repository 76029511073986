import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useFormulas = (uid, type = 'estimate') => {
  const url = `${getApiUrl('v2')}/formula?${type}Uid=${uid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutFormula = () => {
  const toast = useToast();

  const putFormula = async (formulaUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/formula/${formulaUid}`;
      const response = await axios.put(url, payload);
      return response.data;
    } catch (error) {
      toast('An error occurred updating config item');
      throw Error(error);
    }
  };

  return putFormula;
};

export { useFormulas, usePutFormula };
