export const isMsaPremiums = (email) => {
  if (email.includes('.demo@zebel.io')) {
    return true;
  }
  return false;
};

export const isUploadCsvTemplate = (user) => {
  if (user.roles.includes('zebeler')) {
    return true;
  }
  return false;
};

export const isV2Estimates = (user) => {
  if (user.roles.includes('zebeler')) {
    return true;
  }
  return false;
};

export const isFormulaInput = (user) => {
  if (user.roles.includes('zebeler')) {
    return true;
  }
  return false;
};
