const getApiUrl = (apiVersion = 'v1') => {
  if (apiVersion === 'v2') {
    if (window.location.origin.includes('app.eng.zebel.local')) return 'https://api.local.zebel:5001/v1';
    if (window.location.origin.includes('app.zebel.io')) return 'https://api.v2.zebel.io/v1';
    if (window.location.origin.includes('app.dev.zebel.io')) return 'https://api.v2.dev.zebel.io/v1';
  }
  if (window.location.origin.includes('app.eng.zebel.local')) return 'https://app.eng.zebel.local:5000';
  if (window.location.origin.includes('app.dev.zebel.io')) return 'https://api.dev.zebel.io';
  if (window.location.origin.includes('app.zebel.io')) return 'https://api.zebel.io';
};

const getCookieDomain = () => {
  if (window.location.origin.includes('app.eng.zebel.local')) return '.app.eng.zebel.local';
  if (window.location.origin.includes('app.zebel.io')) return '.app.zebel.io';
  if (window.location.origin.includes('app.dev.zebel.io')) return '.app.dev.zebel.io';
};

export { getApiUrl, getCookieDomain };
