import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimateDesignFields = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate-design-field?estimateUid=${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutEstimateDesignField = () => {
  const toast = useToast();
  const putEstimateDesignField = async (estimateDesignFieldUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-design-field/${estimateDesignFieldUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred updating design field');
      throw Error(error);
    }
  };

  return putEstimateDesignField;
};

const usePostEstimateBulkDesignFieldUpdates = () => {
  const postEstimateBulkDesignFieldUpdates = async (estimateUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-design-field/bulk-update/estimate/${estimateUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      window.onerror(error);
      throw Error(error);
    }
  };

  return postEstimateBulkDesignFieldUpdates;
};

export { useEstimateDesignFields, usePutEstimateDesignField, usePostEstimateBulkDesignFieldUpdates };
