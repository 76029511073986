import { useState } from 'react';
import { Snackbar, Box, Alert } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ToastContext } from '@/helpers/toast';

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const handleAddToast = (label = '', severity = 'error') => {
    const toast = { label, severity, uid: uuidv4() };
    setToasts((prev) => [...prev, toast]);
  };

  const handleRemoveToast = (toastUid) => {
    setToasts((prev) => prev.filter((toast) => toast.uid !== toastUid));
  };

  return (
    <ToastContext.Provider value={handleAddToast}>
      {children}
      {toasts.map((toast) => (
        <Snackbar key={toast.uid} open autoHideDuration={3000} onClose={() => handleRemoveToast(toast.uid)}>
          <Box borderRadius="3px" boxShadow="0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)">
            <Alert severity={toast.severity}>{toast.label}</Alert>
          </Box>
        </Snackbar>
      ))}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
