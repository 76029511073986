import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useActivateUser = () => {
  const toast = useToast();
  const activateUser = async (activationUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/user/activate/${activationUid}`;
      const response = await axios.post(url, payload);
      return response.data;
    } catch (error) {
      toast('An error occurred activating user');
      throw Error(error?.response?.data?.message);
    }
  };

  return activateUser;
};

const useGetUserToActivate = (activationUid) => {
  const urlStr = `${getApiUrl('v2')}/user/activate/${activationUid}`;
  const swr = useSWR(urlStr, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const useSelf = () => {
  const urlStr = `${getApiUrl('v2')}/self`;

  const swr = useSWR(urlStr, async (url) => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePutSelf = () => {
  const toast = useToast();
  const putSelf = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/self`;
      const response = await axios.put(url, payload);
      return response.data;
    } catch (error) {
      toast('An error occurred updating user');
      throw Error(error?.response?.data?.message);
    }
  };

  return putSelf;
};

const usePutPassword = () => {
  const toast = useToast();
  const putPassword = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/self/change-password`;
      const response = await axios.put(url, payload);
      toast('Password updated successfully', 'success');
      return response.data;
    } catch (error) {
      toast('An error occurred updating password');
      throw Error(error?.response?.data?.message);
    }
  };

  return putPassword;
};

export { useActivateUser, useGetUserToActivate, useSelf, usePutSelf, usePutPassword };
