import { PRODUCT_TYPE_LABELS, PROJECT_STATUS_LABELS, PROJECT_TYPE_LABELS } from './enums';

const avgUnitSizeMarks = [
  {
    value: 400,
    scaledValue: 400,
    label: '400'
  },
  {
    value: 500,
    scaledValue: 500,
    label: ''
  },
  {
    value: 600,
    scaledValue: 600,
    label: ''
  },
  {
    value: 700,
    scaledValue: 700,
    label: ''
  },
  {
    value: 800,
    scaledValue: 800,
    label: '800'
  },
  {
    value: 900,
    scaledValue: 900,
    label: ''
  },
  {
    value: 1000,
    scaledValue: 1000,
    label: ''
  },
  {
    value: 1100,
    scaledValue: 1100,
    label: ''
  },
  {
    value: 1200,
    scaledValue: 1200,
    label: '1,200'
  },
  {
    value: 1300,
    scaledValue: 1300,
    label: ''
  },

  {
    value: 1400,
    scaledValue: 1400,
    label: ''
  },
  {
    value: 1500,
    scaledValue: 1500,
    label: ''
  },
  {
    value: 1600,
    scaledValue: 1600,
    label: '1,600'
  },
  {
    value: 1700,
    scaledValue: 1700,
    label: ''
  },
  {
    value: 1800,
    scaledValue: 1800,
    label: ''
  },
  {
    value: 1900,
    scaledValue: 1900,
    label: ''
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '2,000'
  }
];

const unitCountMarks = [
  {
    value: 0,
    scaledValue: 0,
    label: '0'
  },
  {
    value: 200,
    scaledValue: 50,
    label: '50'
  },
  {
    value: 400,
    scaledValue: 150,
    label: '150'
  },
  {
    value: 600,
    scaledValue: 300,
    label: '300'
  },
  {
    value: 800,
    scaledValue: 500,
    label: '500'
  },
  {
    value: 1000,
    scaledValue: 1000,
    label: '1K'
  },
  {
    value: 1200,
    scaledValue: 10000,
    label: '1K+'
  }
];

const unionFilterArray = [
  { label: 'All', value: 0 },
  { label: 'Only Union', value: 1 },
  { label: 'Only Non-Union', value: -1 }
];

const rentalTypeArray = [
  { label: 'Market Rate', value: 'market_rate' },
  { label: 'Affordable', value: 'affordable' },
  { label: 'Mixed Income', value: 'mixed_income' }
];

const projectStatusArray = [
  { label: PROJECT_STATUS_LABELS['quick-estimate'], value: 'quick-estimate' },
  { label: PROJECT_STATUS_LABELS['schematic-design'], value: 'schematic-design' },
  { label: PROJECT_STATUS_LABELS['pre-construction'], value: 'pre-construction' },
  { label: PROJECT_STATUS_LABELS['construction-documents'], value: 'construction-documents' },
  { label: PROJECT_STATUS_LABELS.contract, value: 'contract' },
  { label: PROJECT_STATUS_LABELS['in-construction'], value: 'in-construction' },
  { label: PROJECT_STATUS_LABELS.completed, value: 'completed' }
];

const estimateStatusArray = [
  { label: 'Conceptual', value: 'quick-estimate' },
  { label: 'Schematic Design', value: 'schematic-design' },
  { label: 'Design Development', value: 'pre-construction' },
  { label: 'Construction Documents', value: 'construction-documents' },
  { label: 'Contract', value: 'contract' }
];

const projectTypeArray = [
  { label: PROJECT_TYPE_LABELS.multifamily, value: 'multifamily' },
  { label: PROJECT_TYPE_LABELS.student_housing, value: 'student_housing' },
  { label: PROJECT_TYPE_LABELS.senior_housing, value: 'senior_housing' },
  { label: PROJECT_TYPE_LABELS.singlefamily, value: 'singlefamily' },
  { label: PROJECT_TYPE_LABELS.industrial, value: 'industrial' },
  { label: PROJECT_TYPE_LABELS.hospital, value: 'hospital' },
  { label: PROJECT_TYPE_LABELS.school, value: 'school' },
  { label: PROJECT_TYPE_LABELS.storage, value: 'storage' },
  { label: PROJECT_TYPE_LABELS.life_sciences, value: 'life_sciences' },
  { label: PROJECT_TYPE_LABELS.office, value: 'office' },
  { label: PROJECT_TYPE_LABELS.hotel, value: 'hotel' },
  { label: PROJECT_TYPE_LABELS.religion, value: 'religion' },
  { label: PROJECT_TYPE_LABELS.various, value: 'various' }
];

const multifamilyProductTypeArray = [
  { label: PRODUCT_TYPE_LABELS.podium, value: 'podium' },
  { label: PRODUCT_TYPE_LABELS.wrap, value: 'wrap' },
  { label: PRODUCT_TYPE_LABELS.slab_on_grade, value: 'slab_on_grade' },
  { label: PRODUCT_TYPE_LABELS.wood_podium, value: 'wood_podium' },
  { label: PRODUCT_TYPE_LABELS.various, value: 'various' },
  { label: PRODUCT_TYPE_LABELS.high_rise, value: 'high_rise' }
];

const industrialProductTypeArray = [
  { label: PRODUCT_TYPE_LABELS.warehouse, value: 'warehouse' },
  { label: PRODUCT_TYPE_LABELS.light_manufacturing, value: 'light_manufacturing' },
  { label: PRODUCT_TYPE_LABELS.heavy_manufacturing, value: 'heavy_manufacturing' },
  { label: PRODUCT_TYPE_LABELS.cold_storage, value: 'cold_storage' }
];

const constructionTypeArray = [
  { label: 'Type I', value: 'Type I' },
  { label: 'Type II', value: 'Type II' },
  { label: 'Type III', value: 'Type III' },
  { label: 'Type V', value: 'Type V' },
  { label: 'Various', value: 'Various' }
];

const buyoutTimeArray = [
  { label: 'Last Year', value: 1 },
  { label: 'Last Two Years', value: 2 },
  { label: 'Last Three Years', value: 3 },
  { label: 'No Time Limit', value: 100 }
];

const multifamilyUomFields = {
  '22539d6e2857419ea6e10927d74de3f5': 'NRSF',
  cfad0164f8854f36ad52d34ed29f01d9: 'Unit',
  '99705094935c42c29917436a20f62bbd': 'Lot Size (Gross Acre)',
  dc9922f630c5495ba08953479877845c: 'WGSF',
  d8b2d78a40024fad80060823e7d1fc28: 'Construction Duration (Months)',
  c6e70f2f69444d508387f1cf135afb86: 'WGSF + LinerGSF'
};

const hospitalUomFields = {
  '84e00969fe354494b63f79e89304b2b7': 'Construction Duration'
};

const lifeSciencesUomFields = {
  dbcbc4e1f07a40c19372342303021d12: 'Construction Duration'
};

const officeUomFields = {
  '623672e9eab442e3ac6459e6484937eb': 'Construction Duration'
};

const variousUomFields = {
  efc80c8bfbb34a51b51bea3b46b66fac: 'Construction Duration'
};

const singlefamilyUomFields = {
  efc80c8bfbb34a51b51bea3b46b66fac: 'Construction Duration'
};

const hotelUomFields = {
  '0d6cabbccd64451bb9c38dba4c8a58c1': 'Construction Duration'
};

const schoolUomFields = {
  '5ebec7ac1ae046fd99c3089b5813f14c': 'Construction Duration'
};

const industrialUomFields = {
  '05919a00de78462ca0f5e7b876f7c53d': 'Construction Duration'
};

const states = [
  {
    name: 'Alabama',
    id: 1,
    count: 0,
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    id: 2,
    count: 0,
    abbreviation: 'AK'
  },
  {
    name: 'Arizona',
    id: 4,
    count: 0,
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    id: 5,
    count: 0,
    abbreviation: 'AR'
  },
  {
    name: 'California',
    id: 6,
    count: 0,
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    id: 8,
    count: 0,
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    id: 9,
    count: 0,
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    id: 10,
    count: 0,
    abbreviation: 'DE'
  },
  {
    name: 'District of Columbia',
    id: 11,
    count: 0,
    abbreviation: ''
  },
  {
    name: 'Florida',
    id: 12,
    count: 0,
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    id: 13,
    count: 0,
    abbreviation: 'GA'
  },
  {
    name: 'Hawaii',
    id: 15,
    count: 0,
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    id: 16,
    count: 0,
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    id: 17,
    count: 0,
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    id: 18,
    count: 0,
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    id: 19,
    count: 0,
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    id: 20,
    count: 0,
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    id: 21,
    count: 0,
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    id: 22,
    count: 0,
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    id: 23,
    count: 0,
    abbreviation: 'ME'
  },
  {
    name: 'Maryland',
    id: 24,
    count: 0,
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    id: 25,
    count: 0,
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    id: 26,
    count: 0,
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    id: 27,
    count: 0,
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    id: 28,
    count: 0,
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    id: 29,
    count: 0,
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    id: 30,
    count: 0,
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    id: 31,
    count: 0,
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    id: 32,
    count: 0,
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    id: 33,
    count: 0,
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    id: 34,
    count: 0,
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    id: 35,
    count: 0,
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    id: 36,
    count: 0,
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    id: 37,
    count: 0,
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    id: 38,
    count: 0,
    abbreviation: 'ND'
  },
  {
    name: 'Ohio',
    id: 39,
    count: 0,
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    id: 40,
    count: 0,
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    id: 41,
    count: 0,
    abbreviation: 'OR'
  },
  {
    name: 'Pennsylvania',
    id: 42,
    count: 0,
    abbreviation: 'PA'
  },
  {
    name: 'Rhode Island',
    id: 44,
    count: 0,
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    id: 45,
    count: 0,
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    id: 46,
    count: 0,
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    id: 47,
    count: 0,
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    id: 48,
    count: 0,
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    id: 49,
    count: 0,
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    id: 50,
    count: 0,
    abbreviation: 'VT'
  },
  {
    name: 'Virginia',
    id: 51,
    count: 0,
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    id: 53,
    count: 0,
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    id: 54,
    count: 0,
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    id: 55,
    count: 0,
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    id: 56,
    count: 0,
    abbreviation: 'WY'
  },
  {
    name: 'Puerto Rico',
    id: 72,
    count: 0,
    abbreviation: 'PR'
  }
];

const defaultFilterConfig = {
  escalation: {
    enabled: false
  },
  productType: {
    enabled: true,
    data: { selected: ['podium', 'wrap', 'wood_podium', 'slab_on_grade', 'high_rise', 'various'] }
  },
  constructionType: {
    enabled: false,
    data: { selected: [] }
  },
  projectType: {
    enabled: true,
    data: { selected: ['multifamily'] }
  },
  region: {
    enabled: false,
    data: { regions: [] }
  },
  buyoutTime: {
    enabled: false,
    data: { selected: [] }
  },
  projectStatus: {
    enabled: false,
    data: { selected: [] }
  },
  rentalType: {
    enabled: false,
    data: { selected: [] }
  },
  union: {
    enabled: false,
    data: { selected: [] }
  },
  unitCount: {
    enabled: false,
    data: { minCount: 0, maxCount: 10000 }
  },
  avgUnitSize: {
    enabled: false,
    data: { minCount: 0, maxCount: 10000 }
  }
};

const valueUnitsArray = ['', 'SF', 'Acre', 'DU/Acre', 'CY', 'SF/Stall', 'Stall/Unit', 'SF/Unit', 'LF', 'LS', 'Yes/No', '%', 'EA', 'Months', 'Date', 'Ratio', '$/hour'];

export {
  avgUnitSizeMarks,
  unitCountMarks,
  unionFilterArray,
  rentalTypeArray,
  projectStatusArray,
  estimateStatusArray,
  projectTypeArray,
  multifamilyProductTypeArray,
  industrialProductTypeArray,
  constructionTypeArray,
  buyoutTimeArray,
  multifamilyUomFields,
  hospitalUomFields,
  lifeSciencesUomFields,
  officeUomFields,
  variousUomFields,
  singlefamilyUomFields,
  hotelUomFields,
  schoolUomFields,
  industrialUomFields,
  states,
  defaultFilterConfig,
  valueUnitsArray
};
