import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useCompany = () => {
  const url = `${getApiUrl('v2')}/company`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const useCompanyLogo = () => {
  const url = `${getApiUrl('v2')}/company/logo`;
  const swr = useSWR(
    url,
    async () => {
      try {
        const result = await axios.get(url);
        return result.data;
      } catch (err) {
        throw Error(err);
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return swr;
};

const usePutCompany = () => {
  const toast = useToast();
  const putCompany = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/company`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast('An error occurred updating company');
      throw Error(error);
    }
  };

  return putCompany;
};

export { useCompany, usePutCompany, useCompanyLogo };
